import * as React from 'react'
import { Link } from 'gatsby'

const NotFoundPage = () => {
  return (
    <>
      <div className='text-center'>
        <h1 className='display-1 text-muted'>Oops...</h1>
        <h2>We couldn't find that page.</h2>
        <p>
          <Link to={'/'}>Back to home</Link>.
        </p>
      </div>
    </>
  )
}

export default NotFoundPage
